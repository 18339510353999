<template>
    <div>
        <div v-if="user_group.indexOf('测试组')==-1">
            <cw403></cw403>
        </div>
        <div v-if="user_group.indexOf('测试组')>-1">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    用户权限管理
                </el-breadcrumb-item>
            </el-breadcrumb>
             <el-button style="border-radius:10px;width:100px;float:right;margin-top:-25px;margin-right:30px;" icon="el-icon-document-remove" type="primary"  @click="dialogVisible = true" plain>页面说明</el-button>
        </div>
        <div class="container">
            <div class="handle-box">
                
                <el-select v-model="userName" clearable placeholder="请选择用户" filterable collapse-tags style="margin-right:10px;width:240px;" @change="userSelect">
                    <el-option
                        v-for="item in userList"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                </el-select>
                <el-select v-model="userGroup" clearable placeholder="请选择组" style="margin-right:10px;">
                    <el-option
                        v-for="item in groupList"
                        :key="item.id"
                        :label="item.id"
                        :value="item.id">
                    </el-option>
                </el-select>
                <el-select v-model="userPage" clearable placeholder="请选择页面" style="margin-right:10px;">
                    <el-option
                        v-for="item in pageList"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                </el-select>
            </div>
            
            <div>
                <el-button v-if="userName==''||userGroup==''" disabled style="margin-bottom:10px;" icon="el-icon-circle-plus-outline" type="info" >让用户加入选择的组</el-button>
                <el-button v-if="userName!=''&&userGroup!=''" style="margin-bottom:10px;" icon="el-icon-circle-plus-outline" type="primary" @click="tianjia" >让用户加入选择的组</el-button>
                <el-button v-if="userName==''||userPage==''" disabled style="margin-bottom:10px;" icon="el-icon-circle-plus-outline" type="info" >给用户新增页面</el-button>
                <el-button v-if="userName!=''&&userPage!=''" style="margin-bottom:10px;" icon="el-icon-circle-plus-outline" type="primary" @click="addPage" >给用户新增页面</el-button>
            </div>
            <el-button v-if="userName==''" disabled style="margin-bottom:20px;margin-right:10px;" icon="el-icon-edit" type="info">让用户退出所有的组</el-button>
            <el-button v-if="userName!=''" style="margin-bottom:20px;margin-right:10px;" icon="el-icon-edit" type="danger" @click="tuichu">让用户退出所有的组</el-button>
            <el-button v-if="userName==''" disabled style="margin-bottom:20px;margin-right:10px;" icon="el-icon-edit" type="info">清空用户页面限制</el-button>
            <el-button v-if="userName!=''" style="margin-bottom:20px;margin-right:10px;" icon="el-icon-edit" type="danger" @click="deletePage">清空用户页面限制</el-button>
            <el-button v-if="userName==''" disabled style="margin-bottom:10px;" icon="el-icon-delete" type="info">删除该用户</el-button>
            <el-button v-if="userName!=''" style="margin-bottom:10px;" icon="el-icon-delete" type="danger">删除该用户</el-button>

            <el-table
                v-loading = "pictLoading"
                element-loading-text = "数据正在加载中"
                :data="tableData.slice((currentPage1-1)*pagesize,currentPage1*pagesize)"
                height="550"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                :header-cell-style="{'color':'#696969'}"
            >
            
                <el-table-column prop="username" label="username" min-width="20" ></el-table-column>
                <el-table-column prop="group_name" label="group_name" min-width="40"></el-table-column>
                <el-table-column prop="page_name" label="page_name" min-width="40"></el-table-column>
                 
            </el-table>
            <div class="pagination" style="margin-right:30px;">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage1"
                    :page-sizes="[50, 100, 150, 200]"
                    :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
            
        </div>

        
        </div>
    </div>
</template>

<script>
import { fetchData } from '../../api/index';
import json2csv from 'json2csv'
import cw403 from './403.vue';
export default {
    name: 'basetable',
    components: {
      cw403,
    },
    data() {
        return {
            user_group:localStorage.getItem('user-group'),
            userName:'',
            userGroup:'',
            userPage:'',
            pictLoading:true,
            pagesize:50,
            currentPage1: 1,
            total:0,
        value1: '',
        value2: '',

            dialogVisible: false,
            type:false,
            userList:[],
            groupList:[],
            pageList:["谷歌评价","日报模块"],
            QQ:{
            date:[],
            gameName: [],
            phone:[],
            country:[]
            },
            query:'',
            tableData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1
        };
    },
    created() { 
        /* this.getData(); */
        this.getUser();
        this.getGroup();
        this.getXX();
    },
    methods: {
        tianjia(){
            this.$axios.post(
                    "permission/group/user_add/",
                    {
                        "username":this.userName,
                        "group_name":this.userGroup
                    },
                    {
                        headers:{
                         "Authorization":"JWT " + localStorage.getItem("jwt-token")
                            }
                    }
                    ).then((res=>{
                    alert('添加成功!')
                    this.getXX()
                    })).catch((e) => {})
        },
        addPage(){
            this.$axios.post(
                    "permission/page/user_add/",
                    {
                        "username":this.userName,
                        "page_name":this.userPage
                    },
                    {
                        headers:{
                         "Authorization":"JWT " + localStorage.getItem("jwt-token")
                            }
                    }
                    ).then((res=>{
                    alert('添加成功!')
                    this.getXX()
                    })).catch((e) => {})
        },
        tuichu(){
            this.$axios.post(
                    "permission/group/group_clear/",
                    {
                        "username":this.userName
                    },
                    {
                        headers:{
                         "Authorization":"JWT " + localStorage.getItem("jwt-token")
                            }
                    }
                    ).then((res=>{
                    alert('操作成功!')
                    this.getXX()
                    })).catch((e) => {})
        },
        deletePage(){
            this.$axios.post(
                    "permission/page/clear/",
                    {
                        "username":this.userName
                    },
                    {
                        headers:{
                         "Authorization":"JWT " + localStorage.getItem("jwt-token")
                            }
                    }
                    ).then((res=>{
                    alert('操作成功!')
                    this.getXX()
                    })).catch((e) => {})
        },
      //获取用户名
        getUser(){
            this.$http.get('permission/user_info/get/',{
          headers:{
            "Authorization":"JWT " + localStorage.getItem("jwt-token")
          }
        })
            .then(function(res){
            if(res.data.code==200)
            {
                this.userList=res.data.user_info
            }
            }).catch(function(err){
                    console.log(err);
                })
        },
        //获取组名
        getGroup(){
            this.$http.get('permission/group_info/get/',{
          headers:{
            "Authorization":"JWT " + localStorage.getItem("jwt-token")
          }
        })
            .then(function(res){
            if(res.data.code==200)
            {
                this.groupList=res.data.group_info
            }
            }).catch(function(err){
                    console.log(err);
                })
        },
         //获取用户组信息表
        getXX(){
            this.$http.get('permission/info/',{
          headers:{
            "Authorization":"JWT " + localStorage.getItem("jwt-token")
          }
        })
            .then(function(res){
            if(res.data.code==200)
            {
                this.tableData=res.data.info;
                this.tableData.forEach(item => {
                 if(item.group_name.length==2)
                 {
                     item.group_name[0]+='、'
                 }
                 if(item.group_name.length==3)
                 {
                     item.group_name[0]+='、'
                     item.group_name[1]+='、'
                 }
                 if(item.group_name.length==4)
                 {
                     item.group_name[0]+='、'
                     item.group_name[1]+='、'
                     item.group_name[2]+='、'
                 }
                 if(item.group_name.length==5)
                 {
                     item.group_name[0]+='、'
                     item.group_name[1]+='、'
                     item.group_name[2]+='、'
                     item.group_name[3]+='、'
                 }
                 if(item.group_name.length==6)
                 {
                     item.group_name[0]+='、'
                     item.group_name[1]+='、'
                     item.group_name[2]+='、'
                     item.group_name[3]+='、'
                     item.group_name[4]+='、'
                 }
                 if(item.group_name.length==7)
                 {
                     item.group_name[0]+='、'
                     item.group_name[1]+='、'
                     item.group_name[2]+='、'
                     item.group_name[3]+='、'
                     item.group_name[4]+='、'
                     item.group_name[5]+='、'
                 }
                 if(item.group_name.length==8)
                 {
                     item.group_name[0]+='、'
                     item.group_name[1]+='、'
                     item.group_name[2]+='、'
                     item.group_name[3]+='、'
                     item.group_name[4]+='、'
                     item.group_name[5]+='、'
                     item.group_name[6]+='、'
                 }
                
              });
                this.total=res.data.info.length;
                this.pictLoading=false;
            }
            }).catch(function(err){
                    console.log(err);
                })
        },
        // 触发搜索按钮
        handleSearch() {
            /* this.$set(this.query, 'pageIndex', 1);
            this.getData(); */
            /* alert(this.value2+this.QQ.gameName+this.QQ.phone+this.QQ.country) */
                /* var newList=[]
                this.tableData.forEach(item => {
                    for(var i=0;i<this.QQ.gameName.length;i++){
                    if(item.gameName.indexOf(this.QQ.gameName[i])!=-1)
                    newList.push(item)
                    }
                });
                this.tableData=newList */
                /* this.total=this.tableData.length */
        },
        
        // 分页导航
        /* handlePageChange(val) {
            this.$set(this.query, 'pageIndex', val);
            this.getData();
        }, */
        handleSizeChange(val) {
            this.pagesize=val;
        },
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        //搜索
        search(query){
        return this.tableData.filter(item=>{
                        //注意：ES6中，为字符串提供了一个新方法，叫做String.prototype.includes('要包含的字符串')
                        //如果包含，则返回true，否则返回false
                        if(item.username.includes(query))
                        return item
                    })
      },
      //导出csv文件
       download(){
      //datas：数据，fields：字段名
      try {
        const result = json2csv.parse(this.tableData);
        // 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
            navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
          //IE10或Edge浏览器
          var BOM = "\uFEFF";
          var csvData = new Blob([BOM + result], { type: "text/csv" });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement("a");
          link.href = encodeURI(csvContent);
          link.download = `test.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    getSTime (val) {
        this.value2 = val
    },
    handleSizeChange(val) {
            this.pagesize=val;
        },
        handleCurrentChange(val) {
            this.currentPage1 = val;
        },
    userSelect(){

    },
        

    },
};
</script>
<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>

